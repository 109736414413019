import React, {useState} from 'react';
import {Button, Checkbox, Divider, Form, Input, message, Row, Space} from 'antd';
import Api from "../api";
import {setCookie} from "../helpers";

const Login = ({user, setUser, setHasAccess}) => {
    const [googleAuthId, setGoogleAuthId] = useState('');
    const [authNumber, setAuthNumber] = useState('');

    const handleChange = (e) => {
        console.log(e);
        // Ensure only numeric input
        const value = e;

        // Set the auth number
        setAuthNumber(value);

        // Auto verify when 6 digits are entered
        if (value.length === 6) {
            verifyAuth(value);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            verifyAuth();
        }
    };

    const onFinish = (values) => {
        Api.authEmail(values.email, values.password).then((res) => {
            message.success('Login successful');

            setCookie('fl_admin_access_token', res.data.access_token, 1);

            window.location.reload();
        }).catch(err => {
            if (err.response.data.message === '2FA required for user') {
                setGoogleAuthId(err.response.data.data.user_id)
            } else {
                message.error('Invalid login!');
            }
        })
    };

    const verifyAuth = (value) => {
        Api.authVerify(googleAuthId, value ? value : authNumber).then(res => {
            message.success('Login successful');

            setCookie('fl_admin_access_token', res.data.data.accessToken, 1);

            window.location.reload();
        }).catch(err => {
            message.error('Invalid code')
        })
    };


    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Row type="flex" justify="center" align="middle" style={{height: "100vh", overflow: "hidden"}}>
            <Space direction="vertical">
                <h1 style={{textAlign: "center", color: "white"}}>{googleAuthId ? "2FA Auth" : "Login"}</h1>

                {googleAuthId ? (
                    <Space direction="vertical" style={{textAlign: "center"}} size="large">
                        <div>
                            <span style={{color: "white"}}>Enter number in authenticator:</span>
                        </div>

                        <div>
                            <Input.OTP
                                size="large"
                                style={{ width: "300px" }}
                                value={authNumber}
                                onChange={handleChange}
                                onKeyDown={handleKeyDown}
                                placeholder="123456"
                                autoFocus
                                maxLength={6}  // Limits to 6 characters
                            />
                        </div>

                        <Space justify="center">
                            <Button size="large" type="default" onClick={() => setGoogleAuthId('')}>Go back</Button>
                            <Button size="large" type="primary" onClick={verifyAuth}>Verify code</Button>
                        </Space>
                    </Space>
                ) : (

                    <Form
                        name="basic"
                        labelCol={{span: 24}}
                        wrapperCol={{span: 24}}
                        initialValues={{remember: false}}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        size="large"
                    >
                        <Form.Item
                            label="E-mail"
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your username!',
                                },
                            ]}
                        >
                            <Input size="large"/>
                        </Form.Item>

                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                },
                            ]}
                        >
                            <Input.Password size="large"/>
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" block size="large">
                                Login
                            </Button>
                            <Divider orientation="center">
                                Or
                            </Divider>
                            <a href="https://app.fitlap.ee">
                                <Button type="default" block size="large">
                                    Fitlap app
                                </Button>
                            </a>
                        </Form.Item>
                    </Form>
                )}
            </Space>
        </Row>
    )
};

export default Login;